import ReloadingModalDialog from "../action/ReloadingModalDialog";
import HoshoMoshikomiOrderUketorigakuModal from "./HoshoMoshikomiOrderUketorigakuModal";

class HoshoMoshikomiOrderShow {
    static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action=HoshoMoshikomiOrderShow]").forEach((element) => {
            new HoshoMoshikomiOrderShow(element);
        });
    }

    private hoshoMoshikomiOrderShowElement: HTMLElement;

    private constructor(hoshoMoshikomiOrderShowElement: HTMLElement) {
        this.hoshoMoshikomiOrderShowElement = hoshoMoshikomiOrderShowElement;

        ReloadingModalDialog.initComponent(this.hoshoMoshikomiOrderShowElement);
        HoshoMoshikomiOrderUketorigakuModal.initComponent(this.hoshoMoshikomiOrderShowElement);
    }
}

export default HoshoMoshikomiOrderShow;
