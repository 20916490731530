import ReloadingModalDialog from "../action/ReloadingModalDialog";
import ChumonUketorigakuKanriModal from "./ChumonUketorigakuKanriModal";
import ChumonFuzokuhinModal from "./ChumonFuzokuhinModal";
import ChumonJisshEditModal from "./ChumonJisshEditModal";
import ChumonJisshiNewModal from "./ChumonJisshiNewModal";
import ChumonJisshShowModal from "./ChumonJisshShowModal";
import ChumonOtaiKiroku from "./ChumonOtaiKiroku";

class ChumonShow {
    static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action=ChumonShow]").forEach((element) => {
            new ChumonShow(element);
        });
    }

    private chumonShowElement: HTMLElement;

    private constructor(chumonShowElement: HTMLElement) {
        this.chumonShowElement = chumonShowElement;

        ChumonOtaiKiroku.initComponent(this.chumonShowElement);
        ReloadingModalDialog.initComponent(this.chumonShowElement);
        ChumonJisshShowModal.initComponent(this.chumonShowElement);
        ChumonJisshiNewModal.initComponent(this.chumonShowElement);
        ChumonJisshEditModal.initComponent(this.chumonShowElement);
        ChumonFuzokuhinModal.initComponent(this.chumonShowElement);
        ChumonUketorigakuKanriModal.initComponent(this.chumonShowElement);
    }
}

export default ChumonShow;
