import ObjectUtils from "../lib/ObjectUtils";
import ChangeChumonKubun from "./ChangeChumonKubun";
import ClearSearch from "./ClearSearch";
import ReloadingModalDialog from "../action/ReloadingModalDialog"

const bootstrap = require('bootstrap');

class ChumonIndex {
    static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action=ChumonIndex]").forEach((element) => {
            new ChumonIndex(element);
        });
    }

    private chumonIndexElement: HTMLElement;

    private constructor(chumonIndexElement: HTMLElement) {
        this.chumonIndexElement = chumonIndexElement;

        // 注文区分のチェンジイベント
        ChangeChumonKubun.initComponent(this.chumonIndexElement);

        // クリア処理
        ClearSearch.initComponent(this.chumonIndexElement);

        ReloadingModalDialog.initComponent(this.chumonIndexElement);

    }

}

export default ChumonIndex;
