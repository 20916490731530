import ReloadingModalDialog from "../action/ReloadingModalDialog";
import ChangeNyukinKubun from "../action/ChangeNyukinKubun";


class ChumonUketorigakuKanriModal {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLElement>("[data-action=ChumonUketorigakuKanriModal]").forEach((element) => {
            new ReloadingModalDialog(element, parentElement);
            new ChumonUketorigakuKanriModal(element, parentElement);
        });
    }

    private chumonUketorigakuKanriModalElement: HTMLElement;
    private parentElement: HTMLElement;

    private constructor(chumonUketorigakuKanriModalElement: HTMLElement, parentElement: HTMLElement) {
        this.chumonUketorigakuKanriModalElement = chumonUketorigakuKanriModalElement;
        this.parentElement = parentElement;

        this.chumonUketorigakuKanriModalElement.addEventListener('ReloadingModalDialog:reloaded', event => this.handleReloaded(event));
    }

    private handleReloaded(event: Event) {
        ChangeNyukinKubun.initComponent(this.chumonUketorigakuKanriModalElement);
    }
}

export default ChumonUketorigakuKanriModal;
