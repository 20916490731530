import ObjectUtils from "../lib/ObjectUtils";

export default class ChangeNyukinKubun {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLSelectElement>("[data-action=ChangeNyukinKubun]").forEach((element) => {
            new ChangeNyukinKubun(element, parentElement);
        });
    }

    private changeNyukinKubunElement: HTMLSelectElement;                               // 基準要素
    private parentElement: HTMLElement;                                                // 親要素

    private constructor(changeNyukinKubunElement: HTMLSelectElement, parentElement: HTMLElement) {
        this.changeNyukinKubunElement = changeNyukinKubunElement;
        this.parentElement = parentElement;

        //「入金区分」を変更した時にイベント発生
        this.changeNyukinKubunElement.addEventListener('change', event => this.handleChange(event));

    }

    private handleChange(event: any): any {

        let dairiten = ObjectUtils.require(this.parentElement.querySelector("#dairitenId"), HTMLSelectElement);

        this.parentElement.querySelectorAll<HTMLOptionElement>("[data-dairiten-kubun]").forEach((element) => {

            if (event.currentTarget.value === "CREDIT") {

                // WITH の場合、ウィズを選択する
                if (element.dataset["dairitenKubun"] === "WITH") {

                    let index = ObjectUtils.require(dairiten.querySelector("[data-dairiten-kubun='WITH']"), HTMLOptionElement).index;
                    dairiten.selectedIndex = index;
                }

            }
        });
    }
}
