import ReloadingModalDialog from "../action/ReloadingModalDialog";
import ChangeNyukinKubun from "../action/ChangeNyukinKubun";

class ShuriOrderUketorigakuKanriModal {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLElement>("[data-action=ShuriOrderUketorigakuKanriModal]").forEach((element) => {
            new ReloadingModalDialog(element, parentElement);
            new ShuriOrderUketorigakuKanriModal(element, parentElement);
        });
    }

    private shuriOrderUketorigakuKanriModalElement: HTMLElement;
    private parentElement: HTMLElement;

    private constructor(shuriOrderUketorigakuKanriModalElement: HTMLElement, parentElement: HTMLElement) {
        this.shuriOrderUketorigakuKanriModalElement = shuriOrderUketorigakuKanriModalElement;
        this.parentElement = parentElement;

        this.shuriOrderUketorigakuKanriModalElement.addEventListener('ReloadingModalDialog:reloaded', event => this.handleReloaded(event));
    }

    private handleReloaded(event: Event) {
        ChangeNyukinKubun.initComponent(this.shuriOrderUketorigakuKanriModalElement);
    }
}

export default ShuriOrderUketorigakuKanriModal;
