import ReloadingModalDialog from "../action/ReloadingModalDialog";
import FuzokuhinOrderFuzokuhinModal from "./FuzokuhinOrderFuzokuhinModal";
import FuzokuhinOrderUketorigakuKanriModal from "./FuzokuhinOrderUketorigakuKanriModal";

class FuzokuhinOrderShow {
    static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action=FuzokuhinOrderShow]").forEach((element) => {
            new FuzokuhinOrderShow(element);
        });
    }

    private fuzokuhinOrderShowElement: HTMLElement;

    private constructor(fuzokuhinOrderShowElement: HTMLElement) {
        this.fuzokuhinOrderShowElement = fuzokuhinOrderShowElement;

        ReloadingModalDialog.initComponent(this.fuzokuhinOrderShowElement);
        FuzokuhinOrderFuzokuhinModal.initComponent(this.fuzokuhinOrderShowElement);
        FuzokuhinOrderUketorigakuKanriModal.initComponent(this.fuzokuhinOrderShowElement);
    }
}

export default FuzokuhinOrderShow;
