import ChangeHikiwatashiYotei from "../action/ChangeHikiwatashiYotei";
import ChangeSeisanduki from "../action/ChangeSeisanduki";

class FuzokuhinOrderNew {
    static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action=FuzokuhinOrderNew]").forEach((element) => {
            new FuzokuhinOrderNew(element);
        });
    }

    private fuzokuhinOrderNewElement: HTMLElement;

    private constructor(fuzokuhinOrderNewElement: HTMLElement) {
        this.fuzokuhinOrderNewElement = fuzokuhinOrderNewElement;

        ChangeHikiwatashiYotei.initComponent(this.fuzokuhinOrderNewElement);
        ChangeSeisanduki.initComponent(this.fuzokuhinOrderNewElement);
    }
}

export default FuzokuhinOrderNew;