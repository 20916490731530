import ReloadingModalDialog from "../action/ReloadingModalDialog";
import ChangeNyukinKubun from "../action/ChangeNyukinKubun";

class HoshoMoshikomiOrderUketorigakuModal {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLElement>("[data-action=HoshoMoshikomiOrderUketorigakuModal]").forEach((element) => {
            new ReloadingModalDialog(element, parentElement);
            new HoshoMoshikomiOrderUketorigakuModal(element, parentElement);
        });
    }

    private hoshoMoshikomiOrderUketorigakuModalElement: HTMLElement;
    private parentElement: HTMLElement;

    private constructor(hoshoMoshikomiOrderUketorigakuModalElement: HTMLElement, parentElement: HTMLElement) {
        this.hoshoMoshikomiOrderUketorigakuModalElement = hoshoMoshikomiOrderUketorigakuModalElement;
        this.parentElement = parentElement;

        this.hoshoMoshikomiOrderUketorigakuModalElement.addEventListener('ReloadingModalDialog:reloaded', event => this.handleReloaded(event));
    }

    private handleReloaded(event: Event) {
        ChangeNyukinKubun.initComponent(this.hoshoMoshikomiOrderUketorigakuModalElement);
    }
}

export default HoshoMoshikomiOrderUketorigakuModal;
