import ReloadingModalDialog from "../action/ReloadingModalDialog";
import ShuriOrderJisshiEditModal from "./ShuriOrderJisshiEditModal";
import ShuriOrderJisshiNewModal from "./ShuriOrderJisshiNewModal";
import ShuriOrderJisshiShowModal from "./ShuriOrderJisshiShowModal";
import ShuriOrderUketorigakuKanriModal from "./ShuriOrderUketorigakuKanriModal";

class ShuriOrderShow {
    static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action=ShuriOrderShow]").forEach((element) => {
            new ShuriOrderShow(element);
        });
    }

    private shuriOrderShowElement: HTMLElement;

    private constructor(shuriOrderShowElement: HTMLElement) {
        this.shuriOrderShowElement = shuriOrderShowElement;

        ReloadingModalDialog.initComponent(this.shuriOrderShowElement);
        ShuriOrderJisshiShowModal.initComponent(this.shuriOrderShowElement);
        ShuriOrderJisshiNewModal.initComponent(this.shuriOrderShowElement);
        ShuriOrderJisshiEditModal.initComponent(this.shuriOrderShowElement);
        ShuriOrderUketorigakuKanriModal.initComponent(this.shuriOrderShowElement);
    }
}

export default ShuriOrderShow;