import BooleanUtils from "../lib/BooleanUtils";
import StringUtils from "../lib/StringUtils";

export default class ChangeYoyakuShurui {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLSelectElement>("[data-action=ChangeYoyakuShurui]").forEach((element) => {
            new ChangeYoyakuShurui(element, parentElement);
        });

        // 初期値で change イベントを発生させる。
        const yoyakuShuruiElement = parentElement.querySelector<HTMLSelectElement>("#yoyakuShurui");
        yoyakuShuruiElement.dispatchEvent(new Event("change"));
    }

    private changeYoyakuShuruiElement: HTMLSelectElement;                                     // 基準要素
    private parentElement: HTMLElement;                                                     // 親要素

    private constructor(changeYoyakuShuruiElement: HTMLSelectElement, parentElement: HTMLElement) {
        this.changeYoyakuShuruiElement = changeYoyakuShuruiElement;
        this.parentElement = parentElement;

        // 予約種類を変更した時にイベントを発生させる
        this.changeYoyakuShuruiElement.addEventListener('change', event => this.handleChange(event));
    }

    private handleChange(event: any): any {

        // 注文 ID の制御
        let displayJisshiList = BooleanUtils.parse(StringUtils.require(this.changeYoyakuShuruiElement.selectedOptions[0].dataset["displayJisshiList"]));
        this.parentElement.querySelectorAll("#displayJisshiList").forEach((element) => {
            if (displayJisshiList) {
                element.classList.remove("d-none");
            } else {
                element.classList.add("d-none");
            }
        });

        // 注文 ID の制御
        let displayChumonId = BooleanUtils.parse(StringUtils.require(this.changeYoyakuShuruiElement.selectedOptions[0].dataset["displayChumonId"]));
        this.parentElement.querySelectorAll("#displayChumonId").forEach((element) => {
            if (displayChumonId) {
                element.classList.remove("d-none");
            } else {
                element.classList.add("d-none");
            }
        });

        // 配送品の制御
        let displayHaisohin = BooleanUtils.parse(StringUtils.require(this.changeYoyakuShuruiElement.selectedOptions[0].dataset["displayHaisohin"]));
        this.parentElement.querySelectorAll("#displayHaisohin").forEach((element) => {
            if (displayHaisohin) {
                element.classList.remove("d-none");
            } else {
                element.classList.add("d-none");
            }
        });
    }
}
