import ReloadingModalDialog from "../action/ReloadingModalDialog";
import ChangeNyukinKubun from "../action/ChangeNyukinKubun";

class FuzokuhinOrderUketorigakuKanriModal {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLElement>("[data-action=FuzokuhinOrderUketorigakuKanriModal]").forEach((element) => {
            new ReloadingModalDialog(element, parentElement);
            new FuzokuhinOrderUketorigakuKanriModal(element, parentElement);
        });
    }

    private fuzokuhinOrderUketorigakuKanriModalElement: HTMLElement;
    private parentElement: HTMLElement;

    private constructor(fuzokuhinOrderUketorigakuKanriModalElement: HTMLElement, parentElement: HTMLElement) {
        this.fuzokuhinOrderUketorigakuKanriModalElement = fuzokuhinOrderUketorigakuKanriModalElement;
        this.parentElement = parentElement;

        this.fuzokuhinOrderUketorigakuKanriModalElement.addEventListener('ReloadingModalDialog:reloaded', event => this.handleReloaded(event));
    }

    private handleReloaded(event: Event) {
        ChangeNyukinKubun.initComponent(this.fuzokuhinOrderUketorigakuKanriModalElement);
    }
}

export default FuzokuhinOrderUketorigakuKanriModal;
