import ChangeHikiwatashiYotei from "../action/ChangeHikiwatashiYotei";
import ChangeSeisanduki from "../action/ChangeSeisanduki";
import ConfirmSubmitButton from "../action/ConfirmSubmitButton";

class FuzokuhinOrderEdit {
    static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action=FuzokuhinOrderEdit]").forEach((element) => {
            new FuzokuhinOrderEdit(element);
        });
    }

    private fuzokuhinOrderEditElement: HTMLElement;

    private constructor(fuzokuhinOrderEditElement: HTMLElement) {
        this.fuzokuhinOrderEditElement = fuzokuhinOrderEditElement;
        ChangeHikiwatashiYotei.initComponent(this.fuzokuhinOrderEditElement);
        ConfirmSubmitButton.initComponent(this.fuzokuhinOrderEditElement);
        ChangeSeisanduki.initComponent(this.fuzokuhinOrderEditElement);
    }

}

export default FuzokuhinOrderEdit;